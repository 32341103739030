import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 * banner 分页查询
 * @param params
 */
export function findAdvertisementList(params) {
  return request({
    url: envInfo.bgApp.customerServicePath + '/advert/findAdvertByParam',
    method: 'get',
    params,
  });
}

/**
 *  list - 新增
 * @param params
 */
export function addAdvertise(params) {
  return request({
    url: envInfo.bgApp.customerServicePath + '/advert/addApplet',
    method: 'post',
    data: params,
  });
}

/**
 *  list - 新增
 * @param params
 */
export function editAdvertise(params) {
  return request({
    url: envInfo.bgApp.customerServicePath + '/advert/editAdvertVO',
    method: 'post',
    data: params,
  });
}

/**
 *  新增校验广告开始时间
 * @param params
 */
export function validateStart(params) {
  return request({
    url: envInfo.bgApp.customerServicePath + '/advert/validateTime',
    method: 'get',
    params,
  });
}

/**
 *  启用禁用
 * @param params
 */
export function editUsage(params) {
  return request({
    url: envInfo.bgApp.customerServicePath + '/advert/editDisable',
    method: 'get',
    params,
  });
}
