<template>
  <div class="banner-config-component app-container">
    <div class="filter-line">
      <span>
        <el-button type="primary" @click="addNewHandler">{{
          this.$t("commons.addNew")
        }}</el-button>
      </span>
      <div
        class="header-filter-button"
        @click="dataset.filterLine.show = !dataset.filterLine.show"
        :class="
          dataset.filterLine.show ? 'filter-button-open' : 'filter-button-close'
        "
      >
        <i class="iconfont icon-loudoutu"></i>
        筛选
      </div>
    </div>
    <div>
      <finalTable
        ref="finalTableRef"
        :datas="dataset"
        @tableEventHandler="tableEventHandler"
      />
    </div>
    <!-- 新增/编辑 dialog -->
    <el-dialog
      :visible.sync="bannerObj.dialogVisible"
      :before-close="dialogCloseHandler"
      width="900px"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <template slot="title">
        <span>{{ bannerObj.dialogTitle }}</span>
      </template>
      <advertiseDialog
        v-if="bannerObj.dialogVisible"
        :bannerObj="bannerObj"
        @close="dialogCloseHandler"
        @updateSucesss="updateSucesss"
      />
    </el-dialog>
  </div>
</template>

<script>
import finalTable from "@/components/FinalTable";
import {
  findAdvertisementList,
  editUsage,
} from "@/api/ruge/bms/customerService/advertisement";
import advertiseDialog from "./advertiseDialog.vue";
export default {
  components: {
    finalTable,
    advertiseDialog,
  },
  data() {
    return {
      bannerObj: {
        dialogVisible: false,
        dialogTitle: "新增图片",
        dialogStatus: "add",
        id: "",
      },
      dataset: {
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: true,
        },
        selection: {
          need: false,
          width: "18",
        },
        header: [
          { prop: "fileName", label: "广告标题", width: "" },
          { prop: "fileLink", label: "图片链接", width: "" },
          { prop: "linkType", label: "链接类型", width: "" },
          { prop: "updateBy", label: "发布人", width: "" },
          { prop: "updateTime", label: "修改时间", width: "" },
          { prop: "operation", label: "操作", width: "170" },
        ],
        tableData: [],
        // 搜索行配置
        searchLineConfig: {
          fileName: {
            type: "input",
            label: "广告标题",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入图片标题",
            prefixIcon: "el-icon-search",
          },
          operation: {
            type: "button",
            filterCount: "",
            actionType: "clickEvent",
            eventName: "showMoreFilters",
            label: "更多筛选",
          },
        },
        // 表格内容配置
        detailConfig: {
          updateTime: {
            type: "dateFormat",
          },
          linkType: {
            type: "enumerationColumn",
            emuList: {
              0: "无链接",
              1: "内部链接",
              2: "外部链接",
            },
          },
          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "view",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-view",
                tooltips: "详情",
              },
              {
                actionType: "iconClick",
                eventName: "edit",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-edit",
                tooltips: "编辑",
              },
              {
                actionType: "iconClick",
                eventName: "enableRow",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-view",
                tooltips: "启用",
                hideProp: "state",
                hideValue: "enable",
                hide: false,
              },
              {
                actionType: "iconClick",
                eventName: "disableRow",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-view",
                tooltips: "禁用",
                hideProp: "state",
                hideValue: "disable",
                hide: false,
              },
            ],
          },
        },
        // 高级搜索配置
        advanceFilterConfig: {
          fileName: {
            inline: true,
            value: "",
          },
        },
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
      loadingFlag: false,
      requestParams: {
        current: 1,
        rowCount: 10,
        fileName: null,
      },
    };
  },
  watch: {
    loadingFlag: {
      handler(val) {
        this.$refs.finalTableRef.loadingToggle(val);
      },
    },
  },
  created() {
    this.getTableData();
  },
  methods: {
    updateSucesss() {
      this.$message.success("更新成功！");
      this.bannerObj.dialogVisible = false;
      this.getTableData();
    },
    getTableData() {
      this.loadingFlag = true;
      findAdvertisementList(this.requestParams)
        .then((res) => {
          this.dataset.tableData = res.rows;
          this.dataset.pageVO.total = res.total;
        })
        .finally(() => {
          this.loadingFlag = false;
        });
    },
    addNewHandler() {
      this.bannerObj = {
        dialogVisible: false,
        dialogTitle: "新增图片",
        dialogStatus: "add",
        id: "",
      };
      this.bannerObj.dialogVisible = true;
    },
    tableEventHandler(datas) {
      if (datas.type === "goSearch") {
        this.dataset.pageVO.current = 1;
        this.requestParams.current = 1;
        this.requestParams = { ...this.requestParams, ...datas.params };
        this.getTableData();
      } else if (datas.type === "paginationChange") {
        this.requestParams.current = datas.params.current.page;
        this.requestParams.rowCount = datas.params.current.limit;
        this.getTableData();
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "edit":
            this.bannerObj.dialogTitle = "编辑弹窗广告";
            this.bannerObj.dialogStatus = "edit";
            this.bannerObj = { ...this.bannerObj, ...datas.row };
            this.bannerObj.dialogVisible = true;
            break;
          case "view":
            this.bannerObj.dialogTitle = "弹窗广告详情";
            this.bannerObj.dialogStatus = "view";
            this.bannerObj = { ...this.bannerObj, ...datas.row };
            this.bannerObj.dialogVisible = true;
            break;
          case "enableRow":
            this.usageHandler("enable", datas.row.id);
            break;
          case "disableRow":
            this.usageHandler("disable", datas.row.id);
            break;
        }
      }
    },
    usageHandler(state, id) {
      const params = {
        state: state,
        id: id,
      };
      editUsage(params).then((res) => {
        if (res && res.code && res.code === 200) {
          this.$emit("updateSucesss");
          this.getTableData();
        } else {
          this.$message(res.msg);
        }
      });
    },
    dialogCloseHandler() {
      this.bannerObj.dialogVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.banner-config-component {
  .filter-line {
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      height: 36px;
    }
    .icon-loudoutu {
      font-size: 12px;
    }
    .header-filter-button {
      cursor: pointer;
      width: 84px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
    }
    .filter-button-open {
      color: #409eff;
      background: #ecf5ff;
      border: 1px solid #c6e2ff;
    }
    .filter-button-close {
      color: #2a4158;
      background: #ffffff;
      border: 1px solid #e7e8eb;
    }
  }
}
</style>